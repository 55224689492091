import { StyledButton } from '@components/atoms/Button/Button.styles';
import { HTMLAttributeAnchorTarget, ReactNode } from 'react';
import Spinner from '../Spinner';

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'secondaryPlain'
  | 'grey'
  | 'primaryOutlined'
  | 'stroke'
  | 'strokeBlack'
  | 'pink'
  | 'unstyled';
export type ButtonSize = 'sm' | 'md';

export interface Props {
  variant?: ButtonVariant;
  'aria-label'?: string;
  'aria-expanded'?: boolean;
  'aria-haspopup'?: 'dialog' | boolean;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  children: ReactNode;
  size?: ButtonSize;
  disabled?: boolean;
  isLoading?: boolean;
  linkTarget?: HTMLAttributeAnchorTarget | undefined;
  type?: 'submit' | 'reset' | 'button';
  readonly 'data-testid'?: string;
}

const Button = ({
  children,
  variant = 'primary',
  'aria-label': ariaLabel,
  'aria-expanded': ariaExpanded,
  'aria-haspopup': ariaHasPopup,
  onClick,
  className,
  size = 'md',
  disabled,
  isLoading = false,
  type = 'button',
  'data-testid': dataTestId = 'button',
}: Props) => {
  return (
    <StyledButton
      aria-label={ariaLabel}
      $variant={variant}
      onClick={onClick}
      aria-expanded={ariaExpanded}
      className={className}
      $size={size}
      type={type}
      disabled={disabled}
      aria-haspopup={ariaHasPopup}
      data-testid={dataTestId}
    >
      {isLoading ? <Spinner scale={0.75} /> : children}
    </StyledButton>
  );
};

export default Button;
