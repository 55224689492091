import { LayoutContainer } from '@components/organisms/Container/Container';
import LINKS from '@constants/links';
import useFeature from '@hooks/useFeature';
import { usePathname } from 'next/navigation';
import { ReactNode } from 'react';

const WithLayoutContainer = ({ children }: { children: ReactNode }) => {
  const pathname = usePathname();
  const newRecipePageEnabled = useFeature('FEATURE_NEW_RECIPE_DETAIL_PAGE_MR');

  const isNewRecipeDetailPage = pathname?.startsWith(LINKS.RECIPE) && newRecipePageEnabled;
  const isPageWithoutMargins = pathname === LINKS.FAQ || isNewRecipeDetailPage;

  return isPageWithoutMargins ? children : <LayoutContainer>{children}</LayoutContainer>;
};

export default WithLayoutContainer;
