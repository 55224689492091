import getCategory from '@api/getCategory';
import { Product } from '@components/molecules/Product/ProductDataMapper/types';
import { mapCategories } from '@helpers/productMapper';
import useAssortmentKey, { AssortmentKey } from '@hooks/useAssortmentKey';
import { useSearchParams } from 'next/navigation';
import useSWRInfinite from 'swr/infinite';
import { FormattedCategoryData } from './types';

export const SIZE = {
  MOBILE: 10,
  TABLET: 20,
  DESKTOP: 30,
};

const getLastResult = (d: FormattedCategoryData[]) => (d && d.length > 0 ? d[d.length - 1] : undefined);

interface Key {
  fn: string;
  q?: string | null;
  categoryPath: string;
  page?: number;
  sort?: string | null;
  size: number;
  assortmentKey?: AssortmentKey;
}

const fetcher = (key: Key) => {
  const { q, categoryPath, page = 0, sort } = key;
  return getCategory({
    categoryPath: categoryPath,
    size: SIZE.DESKTOP,
    q,
    page,
    sort,
  });
};

interface useCategoryProps {
  categoryPath: string;
}

const useCategory = ({ categoryPath }: useCategoryProps) => {
  const assortmentKey = useAssortmentKey();
  const searchParams = useSearchParams();

  const q = searchParams?.get('q') || undefined;
  const sort = searchParams?.get('sort') || undefined;

  const getKey = (page: number): Key | null => {
    return {
      fn: `/c/${categoryPath}`,
      categoryPath,
      size: SIZE.DESKTOP,
      page,
      q,
      sort,
      ...assortmentKey,
    };
  };

  const {
    data = [],
    setSize,
    mutate,
    isValidating,
    isLoading,
  } = useSWRInfinite(getKey, fetcher, {
    revalidateFirstPage: true,
    keepPreviousData: true,
  });

  const products: Product[] = data.filter((r) => r.formattedResults).flatMap((r) => r.formattedResults);

  const {
    pagination,
    facets,
    sorts,
    categoryInfo,
    superCategories,
    subCategories,
    restrictedProductTypes,
    breadcrumbs,
  } = getLastResult(data) || {};
  const {
    currentPage = 0,
    numberOfPages = 0,
    totalNumberOfResults = 0,
    allProductsInCategoriesCount,
  } = pagination || {};

  const hasMorePages = currentPage + 1 < numberOfPages;

  const loadMore = () => {
    if (hasMorePages) {
      try {
        setSize((s) => s + 1);
      } catch (e) {
        // do nothing
      }
    }
  };

  return {
    categoryInfo,
    products,
    facets,
    breadcrumbs,
    sorts,
    superCategories,
    subCategories: mapCategories(subCategories),
    restrictedProductTypes,
    hasMorePages,
    totalNumberOfResults,
    allProductsInCategoriesCount,
    loadMore,
    mutate,
    isValidating,
    isLoading,
  };
};

export default useCategory;
