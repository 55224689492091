import { AxfoodProductDetailsViewModel } from '@api/generated/storefront';
import ProductDescriptionListing from '@components/molecules/ProductDescriptionListing';
import ProductInformationListing from '@components/molecules/ProductInformationListing';
import ProductOtherListing from '@components/molecules/ProductOtherListing';
import Tabs from '@components/molecules/Tabs';
import TAB_TYPES, { TabType } from '@constants/productDetailTabs';
import { productDetailTracker } from '@trackers';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useRef, useState } from 'react';
import ProductDetailsAccordion from '../Accordion/ProductDetailsAccordion';
import { TabsContentWrapper, TabsWrapper } from './ProductDetailsTabSection.styles';

interface IProductTabsSectionProps {
  product: AxfoodProductDetailsViewModel;
  isTabletPortraitOrGreater: boolean;
  updatedSelectedTab?: TabType | '';
  setUpdatedSelectedTab?: (tabId: TabType | '') => void;
  isPage: boolean;
}

const ProductDetailsTabSection = ({
  product,
  isTabletPortraitOrGreater,
  updatedSelectedTab = '',
  setUpdatedSelectedTab,
  isPage,
}: IProductTabsSectionProps) => {
  const { t } = useTranslation('productDetails');

  const detailSections = [
    {
      id: TAB_TYPES.PRODUCTINFORMATION,
      translation: t('productDetails->desktopTabs->productInformation'),
      hasData: true,
    },
    {
      id: TAB_TYPES.NUTRITIONVALUE,
      translation: t('productDetails->desktopTabs->nutritionValue'),
      hasData: !!product.nutritionDescription || !!product.animalData,
    },
    {
      id: TAB_TYPES.MISC,
      translation: t('productDetails->desktopTabs->misc'),
      hasData:
        !!product.animalData?.feedingData ||
        !!product.animalData?.feedingInstructions ||
        !!product.energyDeclaration?.image,
    },
  ];
  const initiallyExpanded = product.activeSubstance || isPage ? 'productInformation' : '';
  const [tab, setTab] = useState<TabType | ''>(initiallyExpanded);
  const tabContentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setTab(initiallyExpanded);
  }, [product, initiallyExpanded]);

  useEffect(() => {
    if (updatedSelectedTab && setUpdatedSelectedTab) {
      setTab(updatedSelectedTab);
      setTimeout(() => {
        if (tabContentRef && tabContentRef.current) {
          tabContentRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, 1000);
      setUpdatedSelectedTab('');
    }
  }, [updatedSelectedTab, setUpdatedSelectedTab]);

  const handleTabChange = (tabId: TabType) => {
    const currentTabSelected = detailSections.find((element) => element.id === tabId);
    if (tabId) {
      productDetailTracker.productTabClickTracker(currentTabSelected?.translation);
    }
    setTab(tabId);
  };

  const showTabsContent = tab !== '';

  return !isTabletPortraitOrGreater ? (
    <ProductDetailsAccordion
      accordionSections={detailSections}
      onChange={(tabId: string) => handleTabChange(tabId as TabType)}
      product={product}
      initiallyExpanded={initiallyExpanded}
      updatedSelectedTab={updatedSelectedTab}
    />
  ) : (
    <>
      <TabsWrapper ref={tabContentRef}>
        <Tabs
          variant="filled"
          tabs={detailSections}
          canHaveDisabledTabs
          canHaveNoActiveTab
          onChange={(tabId: string) => handleTabChange(tabId as TabType)}
          initialActiveTabId={tab}
        />
      </TabsWrapper>
      {showTabsContent && (
        <TabsContentWrapper>
          {tab === TAB_TYPES.PRODUCTINFORMATION && <ProductDescriptionListing product={product} />}
          {tab === TAB_TYPES.NUTRITIONVALUE && <ProductInformationListing product={product} />}
          {tab === TAB_TYPES.MISC && <ProductOtherListing product={product} />}
        </TabsContentWrapper>
      )}
    </>
  );
};

export default ProductDetailsTabSection;
