import media from '@constants/responsive';
import { focusBoxShadow } from '@styles/utils';
import { rem } from 'polished';
import styled, { css } from 'styled-components';
import { ButtonSize, ButtonVariant } from './Button';

const primary = css`
  background-color: ${({ theme }) => theme.colors.colorAlizarin};
  border: none;

  &,
  & svg {
    color: ${({ theme }) => theme.colors.colorWhite};
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.colorCardinal};
    }
    &:active {
      background-color: ${({ theme }) => theme.colors.colorCardinal};
    }
  }
`;

const secondary = css`
  background-color: ${({ theme }) => theme.colors.colorWhite};
  color: ${({ theme }) => theme.colors.colorAcadia};
  border: ${rem(1)} solid ${({ theme }) => theme.colors.colorStack};

  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.colorPampas};
    }

    &:active {
      background-color: ${({ theme }) => theme.colors.colorPampas};
    }
  }
`;

const secondaryPlain = css`
  background-color: ${({ theme }) => theme.colors.colorWhite};
  color: ${({ theme }) => theme.colors.colorAcadia};
  border: none;

  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.colorPampas};
    }

    &:active {
      background-color: ${({ theme }) => theme.colors.colorPampas};
    }
  }
`;

const grey = css`
  background-color: ${({ theme }) => theme.colors.colorCararra};
  color: ${({ theme }) => theme.colors.colorAcadia};
  border: none;

  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.colorCararra};
    }
    &:active {
      background-color: ${({ theme }) => theme.colors.colorCararra};
    }
  }
`;

const primaryOutlined = css`
  background-color: ${({ theme }) => theme.colors.colorWhite};
  color: ${({ theme }) => theme.colors.colorPrimary};
  border: 1px solid ${({ theme }) => theme.colors.colorPrimary};

  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.colorPampas};
    }
    &:active {
      background-color: ${({ theme }) => theme.colors.colorCararra};
    }
  }
`;

const stroke = css`
  background-color: initial;
  color: ${({ theme }) => theme.colors.colorAlizarin};
  border: ${rem(1)} solid ${({ theme }) => theme.colors.colorAlizarin};

  @media (hover: hover) {
    &:hover,
    &:active {
      color: ${({ theme }) => theme.colors.colorCardinal};
      border: ${rem(1)} solid ${({ theme }) => theme.colors.colorCardinal};
    }
  }
`;

const strokeBlack = css`
  background-color: initial;
  color: ${({ theme }) => theme.colors.colorTextPrimary};
  border: ${rem(1)} solid ${({ theme }) => theme.colors.colorTextPrimary};

  @media (hover: hover) {
    &:hover,
    &:active {
      color: ${({ theme }) => theme.colors.colorStack};
      border: ${rem(1)} solid ${({ theme }) => theme.colors.colorStack};
    }
  }
`;

const pink = css`
  background-color: ${({ theme }) => theme.colors.colorPrimaryPink};
  color: ${({ theme }) => theme.colors.colorPrimary};
  border: none;

  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.colorBeautyBush};
    }
    &:active {
      background-color: ${({ theme }) => theme.colors.colorPetiteOrchid};
    }
  }
`;

const unstyled = css`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.colorTextPrimary};
  border: none;
  && {
    padding: 0;
    height: auto;
    border-radius: 0;
  }
`;

const lookupVariant = {
  primary,
  secondary,
  secondaryPlain,
  grey,
  primaryOutlined,
  stroke,
  strokeBlack,
  pink,
  unstyled,
};

const sizes = {
  sm: css`
    padding: ${rem(8)} ${rem(12)};
    height: ${rem(32)};
    font-weight: ${({ theme }) => theme.fonts.fontWeight.medium};
  `,
  md: css`
    padding: ${rem(14)} ${rem(16)};
    height: ${rem(40)};
    font-weight: ${({ theme }) => theme.fonts.fontWeight.bold};
  `,
};

const buttonBase = css`
  border-radius: ${rem(32)};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${rem(4)};
  text-decoration: none;
  font-weight: ${({ theme }) => theme.fonts.fontWeight.bold};
  text-align: left;
  cursor: pointer;

  font-size: ${({ theme }) => theme.fonts.fontSize.small};
  ${media.tabletPortrait} {
    font-size: ${({ theme }) => theme.fonts.fontSize.normal};
  }

  &:focus-visible {
    ${focusBoxShadow}
  }
  &:disabled {
    background-color: ${({ theme }) => theme.colors.colorStack};
    color: ${({ theme }) => theme.colors.colorWhite};
    border: none;
    cursor: auto;
  }
`;

export const StyledButton = styled.button<{
  $variant: ButtonVariant;
  $size: ButtonSize;
}>`
  ${({ $variant }) => lookupVariant[$variant]}
  ${({ $size }) => sizes[$size]}
  ${buttonBase}
`;

export const CmsButtonStyling = css`
  ${buttonBase}
  ${lookupVariant['primary']}
  ${sizes['md']}
`;
