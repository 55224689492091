import Icon from '@components/atoms/Icon';
import Link from '@components/atoms/Link';
import Paragraph from '@components/atoms/Paragraph';
import LINKS from '@constants/links';
import RecipeCard, { CookingTime } from '@features/recipes/RecipeCard';
import { formatImgUrl } from '@features/recipes/helpers';
import useFeature from '@hooks/useFeature';
import IconBuyRecipe from '@public/icons/colorIcons/icon-buy-recipe.svg';
import Heading from 'components/atoms/Heading';
import useTranslation from 'next-translate/useTranslation';
import {
  BannerDescription,
  BannerLink,
  BannerListWrapper,
  BannerTextWrapper,
  BannerTextWrapperInner,
  BannerThemeTextWrapper,
  BannerTitle,
  ItemImage,
  ItemImageSmall,
  RecipieBadgeWrapper,
} from './CmsBannerListItem.styles';

export interface CmsBannerListItemProps {
  item: ListBannerLinkComponentType;
  trackingFn: (bannerListItemType: string, bannerListItemLabel: string) => void;
}

const retrieveUrl = (linkData?: AxfoodCMSContentLinkComponent) => {
  if (linkData?.recipeLink) return LINKS.RECIPE + linkData?.recipeLink;
  if (linkData?.url) return linkData?.url;
  return '';
};

const ThemeBannerItem = ({ item, trackingFn }: CmsBannerListItemProps) => (
  <BannerListWrapper data-testid="cms-banner-item">
    <Link
      href={retrieveUrl(item.link)}
      onClick={() => trackingFn(item.showBuyRecipeSplash, `${item.bannerTitle} ${item.bannerDescription}`)}
      data-testid="banner-list-link"
    >
      <ItemImage $imgUrl={item.bannerImage?.url as string} />
      <BannerThemeTextWrapper>
        <BannerTextWrapperInner $themeBanner>
          <BannerTitle $themeBanner>
            <Paragraph color="white">{item.bannerTitle}</Paragraph>
          </BannerTitle>
          <BannerDescription>
            <Heading type="h3" color="white">
              {item.bannerDescription}
            </Heading>
          </BannerDescription>
        </BannerTextWrapperInner>
      </BannerThemeTextWrapper>
    </Link>
  </BannerListWrapper>
);

const DeprecatedRecipeBannerItem = ({ item, trackingFn }: CmsBannerListItemProps) => {
  const { t } = useTranslation('listbannercomponent');

  return (
    <BannerListWrapper data-testid="cms-banner-item">
      <Link
        href={retrieveUrl(item.link)}
        key={item.uid}
        onClick={() => trackingFn(item.showBuyRecipeSplash, item.bannerTitle)}
        data-testid="banner-list-link"
        underlined={false}
      >
        {item.showBuyRecipeSplash === 'true' && (
          <RecipieBadgeWrapper data-testid="recipe-splash-icon">
            <Icon size={64} icon={IconBuyRecipe} data-testid="icon-buy-recipe" />
          </RecipieBadgeWrapper>
        )}
        <ItemImageSmall $imgUrl={item.bannerImage?.url as string} />
        <BannerTextWrapper>
          <BannerTextWrapperInner>
            <BannerTitle>{item.bannerTitle}</BannerTitle>
            <BannerLink>
              <Paragraph color="black">{t('listbannercomponent->readmore')}</Paragraph>
            </BannerLink>
          </BannerTextWrapperInner>
        </BannerTextWrapper>
      </Link>
    </BannerListWrapper>
  );
};

const RecipeBannerItem = ({ item, trackingFn }: CmsBannerListItemProps) => {
  const { bannerTitle, bannerImage, link, showBuyRecipeSplash, cookingTime: cookingTimeAsString, purchasable } = item;
  const isPurchasable = purchasable === 'true' || showBuyRecipeSplash === 'true';
  const onClick = () => trackingFn(isPurchasable ? 'true' : 'false', item.bannerTitle);
  const imageSrc = bannerImage?.url;
  const imageAlt = bannerImage?.altText;
  const cookingTime = cookingTimeAsString ? parseInt(cookingTimeAsString) : 0;

  return (
    <RecipeCard
      data-testid="cms-banner-item"
      variant="small"
      name={bannerTitle}
      subtitle={!!cookingTime && <CookingTime cookingTime={cookingTime} color="black" />}
      imageSrc={imageSrc ? formatImgUrl(imageSrc) : undefined}
      imageAlt={imageAlt}
      linkUrl={retrieveUrl(link)}
      onClick={onClick}
      purchasable={isPurchasable}
    />
  );
};

const CmsBannerListItem = ({ item, trackingFn }: CmsBannerListItemProps) => {
  const showNewRecipeCard = useFeature('FEATURE_NEW_CARD_ON_RECIPE_CAROUSEL_EL');
  if (!item.bannerImage) return null;
  if (item.themePage === 'true') {
    return <ThemeBannerItem item={item} trackingFn={trackingFn} key={item.uid} />;
  }

  return showNewRecipeCard ? (
    <RecipeBannerItem item={item} trackingFn={trackingFn} key={item.uid} />
  ) : (
    <DeprecatedRecipeBannerItem item={item} trackingFn={trackingFn} key={item.uid} />
  );
};

// eslint-disable-next-line fp/no-mutation
CmsBannerListItem.displayName = 'CmsBannerListItem';

export default CmsBannerListItem;
