import IconArrowRight from '@public/icons/regularIcons/icon-arrow-right.svg';
import IconArrowLeft from '@public/icons/regularIcons/icon-arrow-left.svg';
import { ReactNode } from 'react';
import { ButtonIcon, StyledLinkButton } from './ArrowLinkButton.styles';

export type ArrowButtonVariant = 'primary' | 'strokeBlack' | 'secondaryPlain' | 'pink' | 'unstyled';

interface Props {
  children: ReactNode;
  variant: ArrowButtonVariant;
  url: string;
  onClick?: () => void;
  target?: string;
  leftArrow?: boolean;
}

const ArrowLinkButton = ({ children, variant, url, onClick, target, leftArrow = false }: Props) => {
  const color = variant === 'strokeBlack' ? 'black' : 'white';
  const icon = leftArrow ? IconArrowLeft : IconArrowRight;

  return (
    <StyledLinkButton variant={variant} href={url} onClick={onClick} linkTarget={target} $leftArrow={leftArrow}>
      {children}
      <ButtonIcon variant={variant} icon={icon} color={color} size={24} />
    </StyledLinkButton>
  );
};

export default ArrowLinkButton;
